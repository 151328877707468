import { useEffect } from 'react'

import { Spinner, Stack } from '@chakra-ui/react'
import { useRouter } from 'next/router'

import { Route } from 'constants/routes'
import { useAuth } from 'providers/UserProvider'

const FrontPage = () => {
  const router = useRouter()
  const { userData, isFirebaseInitializing } = useAuth()

  useEffect(() => {
    if (isFirebaseInitializing) {
      return
    }
    void router.replace(Route.EzTendaMatchLanding())
  }, [router, isFirebaseInitializing, userData])

  return (
    <Stack height="100vh" justify="center" align="center">
      <Spinner size="xl" />
    </Stack>
  )
}

export default FrontPage
